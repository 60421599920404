import React, {useEffect, useContext} from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { ResultsLayout, PersonalisedResults, Loading } from "../components/ResultsLayout"
import { useStaticQuery, graphql } from "gatsby"
import { Router } from "@reach/router"
import { FlowContext } from "../context/FlowContext"


const PersonalResultsPage = () => {

  const { flowFinished, setFlowFinished } = useContext(FlowContext)

  const data = useStaticQuery(graphql`
    {
      allWpFineoptionrow(sort: {fields: menuOrder, order: ASC}) {
        nodes {
          title
          databaseId
          rowDetails {
            fineOptions {
              ... on WpFineoption {
                title
                databaseId
                link
                settings {
                  excerpt
                  default
                }
              }
            }
          }
        }
      }
    }
  `)

  useEffect(()=>{
    setFlowFinished(false)
  }, [])

  console.log('results finished', flowFinished)

  console.log('hey', data.allWpFineoptionrow.nodes);

  const deepClonedData = deepCopyFunction(data.allWpFineoptionrow.nodes);

  console.log('deep',deepClonedData);

  return (

    <Layout results>
      <SEO title="My Fine Options" />
      <Router>
        <Loading path="/results" />
        
        <PersonalisedResults path="/results/:hashid" fineData={deepClonedData} />
      </Router>
    </Layout>
  )
}

export default PersonalResultsPage

const deepCopyFunction = (inObject) => {
  let outObject, value, key

  if (typeof inObject !== "object" || inObject === null) {
    return inObject // Return the value if inObject is not an object
  }

  // Create an array or object to hold the values
  outObject = Array.isArray(inObject) ? [] : {}

  for (key in inObject) {
    value = inObject[key]

    // Recursively (deep) copy for nested objects, including arrays
    outObject[key] = deepCopyFunction(value)
  }

  return outObject
}

